$rem-fallback: true;

// Colors
@import 'fontAwesome/fontawesome.scss';
@import '~bootstrap/scss/bootstrap';
@import 'bootstrap-override.scss';
@import 'bandeau-user.scss';
@import 'rem';
@import 'fontface';

@include font-face("Gotham-Book", "../fonts/Gotham/Gotham-Book", 325, normal);
@include font-face("Gotham-Light", "../fonts/Gotham/Gotham-Light", 300, normal);

// Fonts sizes
.fsize0 { font-size: inherit !important }
.fsize3 { font-size: rem(3px) };
.fsize5 { font-size: rem(5px) };
.fsize8 { font-size: rem(8px) };
.fsize10 { font-size: rem(10px) };
.fsize11 { font-size: rem(11px) };
.fsize12 { font-size: rem(12px) };
.fsize13 { font-size: rem(13px) };
.fsize14 { font-size: rem(14px) };
.fsize15 { font-size: rem(15px) };
.fsize16 { font-size: rem(16px) };
.fsize17 { font-size: rem(17px) };
.fsize18 { font-size: rem(18px) };
.fsize20 { font-size: rem(20px) };
.fsize22 { font-size: rem(22px) };
.fsize24 { font-size: rem(24px) };
.fsize25 { font-size: rem(25px) };
.fsize28 { font-size: rem(28px) };
.fsize26 { font-size: rem(26px) };
.fsize30 { font-size: rem(30px) };
.fsize32 { font-size: rem(32px) };
.fsize34 { font-size: rem(34px) };
.fsize36 { font-size: rem(36px) };
.fsize40 { font-size: rem(40px) };
.fsize42 { font-size: rem(42px) };
.fsize48 { font-size: rem(48px) };
.fsize50 { font-size: rem(50px) };
.fsize57 { font-size: rem(47px) };
.fsize60 { font-size: rem(60px) };
// END Fonts sizes

.gotham-light {font-family: 'Gotham-Light', sans-serif;}
.gotham-book {font-family: 'Gotham-Book', sans-serif;}
.ptsanscaption { font-family: 'PT Sans Caption', sans-serif; }
.sedgwick { font-family: 'Sedgwick Ave', cursive; }

// Global
* {
    outline: none !important;
}

html, body {
    @extend .gotham-book;
}

h2,
h3,
h4,
h5,
h6 {
    @extend .ptsanscaption;
}

h2 {
    font-size: rem(33px);
}
h3 {
    font-size: rem(29px);
}

a {
    color: $body-color;

    &:hover {
        color: $orange;
    }

    &.no-underline {
        text-decoration: none;
    }
}

.btn {
    @extend .ptsanscaption;
    text-transform: uppercase;
    border-radius: 0;
    position: relative;

    .fa-long-arrow-right {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -8px;
    }
}

.btn-primary{
    background-color: $primary;
    border-color: $primary;
}

.btn-primary:hover{
    background-color: $blueHover;
    border-color: $blueHover;
}

.modal-content {
    border-radius: 0;
}
.modal-footer,
.modal-header {
    border: none;
}
.modal-title {
    font-weight: 400;
    font-size: rem(20px);
    text-align: center;
}

.grid-item div {
    -webkit-filter: brightness(80%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.grid-item div:hover {
    -webkit-filter: brightness(100%);
}


.form-control {
    border-radius: 0;
}

#languageLi{
    text-align: center;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        color: #ffffff !important;
    }
    span {
        color: #ffffff !important;
        &.activeLanguage{
            color: #000000 !important;
        }
    }
}

#mapid { height: 416px; }

#shop-recap.agis-card {
    min-height: 380px;
    height: auto;

    &.ajax-loader {
        background-image: url('/build/img/site/ajax-loader.gif');
        background-position: center center;
        background-repeat: no-repeat;
    }
}

#modalClientConnexion {
    form {
        border-bottom: 1px solid #cac6c6;
    }
}

#section__panier-info,
#accordionPastCommande,
#basketModal {
    .table td {
        vertical-align: middle;
    }
}

#dropdownBasket:hover,
#dropdownBasket:focus {
    text-decoration: none;
}

#dropdownBasketMenu {
    background: #2b2927;
    color: #FFF;
    border: none;
    border-radius: 0;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 25rem;
    text-align: center;
    margin-left: -12.5rem;
    margin-top: 1rem;

    .basket-menu__footer {
        padding-left: 15px;
        padding-right: 15px;
    }

    .basket-item {
        border-bottom: 1px solid rgba(255,255,255,0.3);
        margin-bottom: 15px;
        padding-bottom: 15px;
    }
}

#shop-recap .card-footer .shop-recap__title {
    display: block;
    padding-right: 0.5rem;
    width: 45%;
    border-right: 1px solid #fff;
    vertical-align: middle;
}
#shop-recap .card-footer .shop-recap__info {
    width: 55%;
    text-align: right;
}

.fiche-content__shop-form .btn-quantity,
#section__fiche-table .btn-quantity,
#section__panier-info .btn-quantity {
    margin: 0;
    max-width: 120px;
    min-width: 120px;

    .inputQuantity {
        text-align: center;
    }

    .btn {
        &:focus {
            box-shadow: none;
        }
    }
}

#section__panier-info {
    .table-responsive {
        display: table;
    }
}

#section__breadcrumb-shop .breadcrumb-shop .breadcrumb {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border-radius: 0; }
#section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item {
    position: relative;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0.5rem 0.75rem;
    background-color: #dbdfe3;
    height: 50px; }
#section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item a {
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%; }
#section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item a .breadcrumb-shop-badge {
    display: inline-block;
    margin-right: 1rem;
    background-color: #fff;
    color: #dbdfe3;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 2em;
    line-height: 35px; }
#section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item.active {
    background-color: #abb5be; }
#section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item.active a {
    color: #fff; }
#section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item.active a .breadcrumb-shop-badge {
    color: #abb5be; }
#section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item.previous {
    background-color: #abb5be; }
#section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item.previous a {
    opacity: 0.33;
    color: #ffffff; }
#section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item.previous a .breadcrumb-shop-badge {
    color: #abb5be;
    background-color: #ffffff; }
#section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item:not(:last-child):after {
    position: absolute;
    z-index: 15;
    content: "";
    width: 35px;
    height: 35px;
    right: -18px;
    bottom: 8px;
    background-color: transparent;
    transform: rotate(45deg); }
#section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item.active:after {
    background-color: inherit; }
#section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: '';
    padding: 0; }

.breadcrumb {
    background: transparent;
}
.breadcrumb-item + .breadcrumb-item:before {
    content: ">";
    color: $body-color;
}
.breadcrumb-item {
    &.active,
    a {
        color: $body-color;
    }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: #abb5be;
    border-radius: 0;
}

.card {
    border-radius: 0;

    .card-header {
        border-bottom: 0;
        background: #abb5be;
        color: $white;
        border-radius: 0;
    }

    .card-footer {
        padding: 0.25rem 1.25rem;
        background-color: #abb5be;
        border-radius: 0;
        color: $white;
        border-top: 0;
    }
}

.reassurance {
    margin-top: 100px;
}

.pagination {
    .page-link {
        color: $orange;
        border-radius: 0 !important;
    }
    .page-item.active .page-link {
        background-color: $orange;
        border-color: $orange;
    }
}

.categorie-image {
    background: url('/build/img/site/bg-categorie.jpg') no-repeat center top;

    &:hover {
        background-position: center 100%;
    }
}

.produits-recette {
    position: absolute;
    right: 0;
    font-size: 0;

    img {
        max-width: 115px;
    }
}


body {
    background-color: #e3e6ec;

    &.accueil {
        header {
            h1 {
                margin-bottom: 30px;
            }

            .page-accroche {
                margin-bottom: 50px;
            }
        }
    }

    &.categorie {
        .page-accroche {
            h2 {
                margin-bottom: 40px;
                text-transform: uppercase;
            }
            h3 {
                margin-bottom: 30px;
                font-weight: 300;
            }
        }
    }

    &.quisommesnous,
    &.contact {
        header {
            background-image: url('/build/img/site/bandeau-contact.jpg');

            &:after {
                background-image: url('/build/img/site/bandeau-contact-arrow.png');
            }
        }

        footer {
            margin-top: 0;
        }

        #breadcrumb {
            @extend .bg-gray-dark;

            .breadcrumb-item + .breadcrumb-item:before,
            .breadcrumb-item.active,
            .breadcrumb-item a {
                color: $white;
            }
        }
    }

    &.contact {
        .text-wysiwyg a {
            color: $white;
        }
    }
}

h1 {
    @extend .gotham-light;
    text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4);
    margin: 60px 0 0 0;

    &:after {
        content: '';
        display: block;
        height: 1px;
        width: 300px;
        background-color: rgba(255,255,255, 0.3);
        margin: 30px auto 60px auto;
    }
}

nav .submeny a:after{position: absolute; left: 50%; bottom:-2px; margin-left: -7px; width: 0px; height:0px; border-left: 7px solid transparent; border-right: 7px solid transparent; border-top: 7px solid #fbc011; content: "";}
nav .submeny .submeny-top:before{position: absolute; left: 0px; bottom:-11px; width: 100%; content: ""; height: 11px;}
nav .submeny:hover a:after, nav .submeny a.act:after{bottom: -7px;}

header {
    position: relative;
    background: url('/build/img/site/bandeau-header.jpg') no-repeat center bottom;
    background-size: cover;
    background-position: center center;

    &:after {
        content: '';
        display: block;
        width: 106px;
        height: 52px;
        background-image: url('/build/img/site/bandeau-header-arrow.png');
        position: absolute;
        left: 50%;
        margin-left: -53px;
        bottom: -51px;
    }

    .navbar {
        .navbar-nav {
            .page-item {
                background-color: rgba(0, 0, 0, 0);
                margin-bottom: 20px;

                &:hover,
                &.active {
                    background-color: rgba(255, 255, 255, 0.5) !important;
                }
            }

            a.nav-link {
                color: #f5f5f5;
                font-size: 1.1rem !important;

                &:hover,
                &:active,
                &:not(:first-child) {
                    color: #2e3d55;
                    background-color: rgba(255, 255, 255, 0.5) !important;
                }
            }
        }

        /*.nav-first {
            border-left: 1px solid rgba(255,255,255,0.3);
        }*/
    }

    .navbar-brand {
        margin-right: 1rem;

        img {
            max-width: 150px;
        }
    }

    .navbar-light .navbar-nav .page-item .nav-link {
        font-size: rem(15px);
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .navbar-light .navbar-nav .nav-link,
    .navbar-light .navbar-nav .nav-link:hover,
    .navbar-light .navbar-nav .nav-link:focus {
        color: $white;
    }

    .navbar-light .navbar-nav .show > .nav-link,
    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .nav-link.active {
        color: $white;
    }

    .navbar-light .navbar-nav .page-item:hover,
    .navbar-light .navbar-nav .page-item:focus,
    .navbar-light .navbar-nav .page-item.active {
        background-color: rgba(46, 61, 85, .7);
        transition: background-color 0.3s ease;
    }

    .navbar-boutique {
        position: absolute;
        right: 0;
    }
}

footer {
    //background: url('/build/img/site/bandeau-footer.jpg') no-repeat center top;
    padding: 35px 0;
    position: relative;
    margin-top: 100px;
    background-color: $primary;
    color: #006383;

    //&:before {
    //    content: '';
    //    display: block;
    //    background: url('/build/img/site/bandeau-footer-arrow.png') no-repeat center center;
    //    width: 78px;
    //    height: 40px;
    //    position: absolute;
    //    left: 50%;
    //    margin-left: -39px;
    //    top: -38px;
    //    z-index: 2;
    //}

    .copyright {
        opacity: 0.5;

        li:first-child:before {
            display: none;
        }

        li:before {
            content: '|';
            display: inline-block;
            margin-right: 9px;
        }
    }
}

.owl-carousel {
    position: relative;

    .owl-stage{
        display: flex;
    }

    .owl-nav {
        font-size: rem(40px);
        color: #959393;

        .owl-prev,
        .owl-next {
            height: 80px;
            line-height: 80px;
            margin-top: -40px;
            position: absolute;
            top: 50%;
        }
        .owl-prev {
            left: -40px;
        }
        .owl-next {
            right: -40px;
        }
    }

    .owl-dots {
        width: 100%;
        text-align: center;
        z-index: 2;
        margin-bottom: 30px;

        .owl-dot {
            display: inline-block;
            margin: 0 6px;
            width: 11px;
            height: 11px;
            border-radius: 20px;
            background-color: #7f7f7f;

            &.active {
                width: 13px;
                height: 13px;
                background-color: #ffffff;
                border: 2px solid $orange;
            }
        }
    }

    &.owl-carousel-thumb-produit,
    &.owl-carousel-bloc {
        .owl-nav {
            font-size: rem(30px);

            .owl-prev,
            .owl-next {
                height: 60px;
                line-height: 60px;
                margin-top: -30px;
                position: absolute;
                top: 50%;
            }
            .owl-prev {
                left: -30px;
            }
            .owl-next {
                right: -30px;
            }
        }
    }
}

.galerie {
    padding: 0 30px;
}

.galleryImageDiv{
    width: 100%;
    height: 200px;
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.accueil-blocs {
    //background: url('/build/img/site/bordgauche.jpg') left top no-repeat, url('/build/img/site/borddroit.jpg') right top no-repeat;
}

.bloc-caracteristiques {
    .table tr:first-child td {
        border-top: 0;
    }
}

.bonscadeaux-wrapper,
.nosrituels-wrapper,
.nossoins-wrapper,
.visiteguidee-wrapper,
.soinsvisages-wrapper,
.contactplan-wrapper{
    color: $black;
}

.quisommesnous-wrapper {
    //background-image: url('/build/img/site/logo-noir.png');
    //background-repeat: no-repeat;
    //background-position: center top;
    //color: $white;
}

.nossoins-wrapper .row,
.soinsvisages-wrapper .row{
    align-items: center;
}

.avis-note i {
    color: #e4b44d;
}

.pagination {
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
}

.bloc-type-3 {
    .col-lg-5 {
        max-width: 943px;
    }
}

.bloc-type-15 {
    display: flex;
    align-items: center;
}

.text-blue {
    color: #6180b0;
}

.text-gray {
    color: #787878;
}

.highlight-black {
    background-color: $black;
    color: $white;
    padding: 5px;
}

.highlight-white {
    background-color: $white;
    color: $gray-800;
    padding: 5px;
}

.highlight-orange {
    background-color: $orange;
    color: $white;
    padding: 5px;
}

.bloc-fond-2{
	background-color : #e3e6ec;
}

.bloc-14-div {
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

legend {
    border-bottom: 1px solid #ced4da;
}

form {
    p.label,
    label {
        &.required:after {
            content: '*';
            display: inline-block;
            margin-left: 4px;
            color: $red;
            font-size: rem(16px);
            vertical-align: top;
        }
    }

    .form-group {
        position: relative;
    }

    .alert-danger {
        margin-bottom: 1.5rem;
    }

    .form-error-icon.badge,
    .invalid-feedback .badge  {
        display: none;
    }

    /*input[type="radio"].form-check-input,
    input[type="checkbox"].form-check-input {
        display: block;
        position: absolute;
        z-index: 1;
    }*/
    input[type="radio"].form-check-input + label::before {
        background-color: #FFF;
        left: 0px;
        bottom: 1px;
        z-index: 2;
    }
    input[type="radio"].form-check-input + label {
        /*padding-left: 20px;*/
    }

    .form-check-group {
        height: auto !important;
    }

    .form-check-inline {
        label {
            &.required:after {
                display: none;
            }
        }
    }

    fieldset {
        /*margin: 0 !important;*/
        width: 100%;
    }

    input.is-invalid,
    input.error-form,
    textarea.error-form {
        border: 1px solid #a94442;
    }

    .invalid-feedback {
        width: 100%;
        left: 0;
        font-size: 14px;
    }

    .invalid-feedback,
    label.error-form {
        position: relative;
        background: #fff1f2;
        border: 1px solid #ebccd1;
        color: #a94442;
        display: block;
        margin-top: 10px;
        padding: 4px;
        text-transform: none;
        font-weight: normal;
        font-family: 'Quicksand Regular', helvetica, arial, sans-serif;
        font-size: 14px;

        &:after, &:before {
            bottom: 100%;
            left: 20px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        &:after {
            border-color: rgba(255, 241, 242, 0);
            border-bottom-color: #fff1f2;
            border-width: 10px;
            margin-left: -10px;
        }
        &:before {
            border-color: rgba(241, 51, 64, 0);
            border-bottom-color: #ebccd1;
            border-width: 11px;
            margin-left: -11px;
        }
    }
}

.formulaire-newsletter{
    //display: flex;
    //align-items: center;
}

.select2-container--default .select2-selection--multiple {
    border-radius: 0 !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #abb5be !important;
    border: 1px solid #abb5be !important;
    color: $white !important;
    border-radius: 0 !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: $white !important;
}

/* Video Modal*/

#videoModal{
    .modal-dialog {
        max-width: 800px;
        margin: 30px auto;
    }
    .modal-body {
        position:relative;
        padding:0px;
    }
    .close {
        position:absolute;
        right:-30px;
        top:0;
        z-index:999;
        font-size:2rem;
        font-weight: normal;
        color:#fff;
        opacity:1;
    }
}

.grid {
}

/* clear fix */
.grid:after {
    content: '';
    display: block;
    clear: both;
}

/* ---- .grid-item ---- */

.grid-sizer,
.grid-item {
    width: 25%;
}

.grid-item {
    float: left;
}

.grid-item img {
    display: block;
    max-width: 100%;
}


@include media-breakpoint-only(xs) {
    .grid-sizer,
    .grid-item {
        width: 50%;
    }
}
@include media-breakpoint-only(sm) {

    .grid-sizer,
    .grid-item {
        width: 50%;
    }
}
@include media-breakpoint-down(md) {
    header,
    footer {
        background-size: cover;
    }
    header {
        &:after {
            display: none;
        }

        .navbar .page-item {
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-left: 1px solid rgba(255, 255, 255, 0.3) !important;
            border-top: none !important;

            &.nav-first {
                border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
            }
        }

        .navbar-brand {
            margin-right: 0;
            width: 100%;
            margin-bottom: 15px;

            img {
                max-width: 200px;
            }
        }

        .navbar-toggler {
            border: none;
            color: $white;
            padding: 0;
            border-radius: 0;
            font-size: rem(30px);
            outline: none;

            .navbar-toggler-icon {
                outline: none;
                background-image: none;

                &:before {
                    content: "\F00D";
                    font-family: 'Font Awesome\ 5 Pro';
                    font-weight: 300;
                    font-size: rem(40px);
                    color: $white;
                }
            }

            &.collapsed {
                background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

                .navbar-toggler-icon {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        .dropdown,
        .navbar-boutique {
            position: static;
        }
    }

    footer {
        .copyright li:before {
            display: none;
        }
        .list-inline-item {
            width: 100%;
        }

    }

    h1 {
        font-size: rem(30px) !important;

        &:after {
            width: 100%;
        }
    }

    .nav-item {
        width: 100%;
    }

    .breadcrumb {
        .fa-home,
        li {
            font-size: rem(10px) !important;
        }
    }

    #dropdownBasketMenu {
        width: 100%;
        min-width: auto;
        margin: 0;
    }
    .produits-recette {
        position: static;
    }

    body.accueil header h1 {
        margin-bottom: 150px;
    }
    #section__breadcrumb-shop .breadcrumb-shop .breadcrumb .breadcrumb-item:after {
        display: none !important;
    }
    #section__panier-info {
        .table-responsive {
            display: block;
        }
    }

    .accueil-blocs {
        background: transparent !important;
    }

}
@include media-breakpoint-only(md) {}
@include media-breakpoint-only(lg) {}
@include media-breakpoint-only(xl) {}
@include media-breakpoint-down(lg) {}