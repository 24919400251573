// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//$body-background: #f5f5f5;

// Color system
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #2b2828;
$gray-900: #212529;
$black:    #000;

$grays: ();
$grays: map-merge((
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
), $grays);

$blue:      #2e3d55;
$blueHover: #6180b0;
$indigo:    #6610f2;
$purple:    #6f42c1;
$pink:      #e83e8c;
$red:       #e52424;
$orange:    #eb7c15;
$yellow:    #ffc107;
$green:     #28a745;
$teal:      #abb5be;
$cyan:      #17a2b8;

$colors: ();
$colors: map-merge((
        "blue":       $blue,
        "indigo":     $indigo,
        "purple":     $purple,
        "pink":       $pink,
        "red":        $red,
        "orange":     $orange,
        "yellow":     $yellow,
        "green":      $green,
        "teal":       $teal,
        "cyan":       $cyan,
        "white":      $white,
        "gray":       $gray-600,
        "gray-dark":  $gray-800
), $colors);

$primary:       #73BCC3;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: ();
$theme-colors: map-merge((
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark
), $theme-colors);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900;
$yiq-text-light:            $white;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                                true;
$enable-rounded:                              true;
$enable-shadows:                              false;
$enable-gradients:                            false;
$enable-transitions:                          true;
$enable-prefers-reduced-motion-media-query:   true;
$enable-hover-media-query:                    false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true;
$enable-print-styles:                         true;
$enable-validation-icons:                     true;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3),
        6: ($spacer * 4.5),
        7: ($spacer * 6),
        8: ($spacer * 7.5),
        9: ($spacer * 9),
        10: ($spacer * 10.5),
        11: ($spacer * 12),
        12: ($spacer * 13.5),
        13: ($spacer * 15),
        14: ($spacer * 16.5),
        15: ($spacer * 18),
        16: ($spacer * 19.5),
        17: ($spacer * 21),
        18: ($spacer * 22.5),
        19: ($spacer * 24),
        20: ($spacer * 25.5),
        21: ($spacer * 27),
    ),
    $spacers
);

@each $color, $value in $colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }

    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }

    .text-#{$color} {
        color: $value;
    }

    .bg-#{$color} {
        background-color: $value;
    }

    .border-#{$color} {
        border-color: $value;
    }
}